import React from 'react';
import { PrismicLink } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { slugify } from '../../utils/helperFunctions';
import { FaChevronRight } from "react-icons/fa";
import * as s from './CardStyle.module.scss';
import { AirtableImage, isImage } from '../AirtableImage';
import { useMeta } from '../providers/MetaProvider';


const ProjectPreviewCard = ({project, style}) => {
  const {meta} = useMeta();
  if(!project) return null;
  const {
    Name,
    Logo,
    Description,
    Link,
  } = project;
  return (
    <div className={s.CardPreview} style={style}>
      {isImage(Logo) &&
      <div className={s.logoImageSquare}>
        <AirtableImage
          image={Logo}
          alt={'Project Logo'}
        />
        
      </div>
      }
      <div>
        <h4 className={s.heading}>{Name}</h4>
        <p className={s.truncate1}>{Description}</p>
          <PrismicLink href={Link || `/project/${slugify(Name)}`} className={s.button}>
            {meta.learn_more}
            <FaChevronRight />
          </PrismicLink>
      </div>    
    </div>
  );
};

export default ProjectPreviewCard;