import React from 'react';
import { PrismicLink } from '@prismicio/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { slugify } from '../../utils/helperFunctions';
import { FaChevronRight, FaSchool } from "react-icons/fa";
import * as s from './CardStyle.module.scss';
import { useMeta } from '../providers/MetaProvider';
import { AirtableImage, isImage } from '../AirtableImage';


const SchoolCard = ({school, style}) => {
  const {meta} = useMeta();
  if(!school) return null;
  const {
    Name,
    Logo,
    Description,
    Projects,
    City,
    Country,  
    Link,
  } = school;
  const projects = Projects.map(({ data }) => data.Name).join(', ');
  const country = Country.length === 1 ? Country[0] : null;
  const countryName = country?.data.Name || ""
  const countryImage = getImage(country?.data.Flag?.localFiles[0]?.childImageSharp) || null;

  const link = Link?.url || `/school/${slugify(Name)}`;

  return (
    <div className={s.Card} style={style}>
      <PrismicLink className={s.logoImage} href={link}>
        {isImage(Logo) ? 
          <AirtableImage
            image={Logo} 
            alt={Name} 
          />

          : <div className={s.placeholder}><FaSchool/></div>
        }
      </PrismicLink>
      <PrismicLink href={link} style={{textDecoration: 'none'}}><h3>{Name}</h3></PrismicLink>
      <div className={s.CountryWrap}>
        {countryImage && 
          <div style={{maxWidth: 25, display: 'flex', alignItems: 'center', paddingBottom: 2}}>
            <GatsbyImage
              image={countryImage}
              style={{ borderRadius: 3, height: 'auto', width: '100%'}}
              alt="Country Flag"
            />
          </div>
          
        }
        <p><strong>{City}, {countryName}</strong></p>
      </div>
      <p><strong>Projects: </strong>{projects}</p>

      <p className={s.truncate}>{Description}</p>

      <PrismicLink href={link} className={s.button}>
        Learn more
        <FaChevronRight />
      </PrismicLink>
    </div>
  );
};

export default SchoolCard;