
export function extendArray(arr, length) {
  const result = [];
  while (result.length < length) {
    result.push(...arr);
  }
  return result.slice(0, length);
}
export function stringCombine(strings, separator = " | ") {
  return strings.filter(Boolean).join(separator);
}
export const slugify = str => {
  if(!str) return null
  return str
    .toLowerCase()
    .trim()
    .replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
  }

export const socialHandle = (handle, platform) => {
  if(!handle) return null;
  const validHandle = handle.replace(/[^a-zA-Z0-9]/g, '');
  switch (platform) {
    case 'instagram':
      return `https://www.instagram.com/${validHandle}`
    case 'twitter':
      return `https://www.twitter.com/${validHandle}`
    case 'facebook':
      return `https://www.facebook.com/${validHandle}`
    default:
      return null
  }
}

export const alphabeticalArraySort = (arr, key) => {
  return arr.sort((a, b) => {
    const nameA = a[key].toUpperCase();
    const nameB = b[key].toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}