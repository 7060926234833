import { slugify } from "./helperFunctions";


function uniqueFlatten(arr) {
  return [...new Set(arr.flat())];
}

const keyMergeProject = (project) => {
  if(!project || !project.data) return;
  const { data } = project;
  return {
    ...data,
    Link: `/project/${slugify(data.Name)}`,
    Values: data.FFS_Alignments,
    Sdgs: uniqueFlatten(data.Full_Sdgs.map(({ data }) => data.Title)),
    Regions: uniqueFlatten(data.School_s_.map(({ data }) => data.Region)),
    
  }
}

const keyMergeSchool = (school) => {
  if(!school || !school.data) return;
  const { data } = school;
  return {
    ...data,
    Link: `/school/${slugify(data.Name)}`,
    Values: uniqueFlatten(data.Projects.map(({ data }) => data.FFS_Alignments)),
    Sdgs: uniqueFlatten(data.Projects.map(({ data }) => data.SDGs.map(({ data }) => data.Title))),
    Regions: data.Region,
    Coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)}
  }
}
const keyMergeSchools = (schools) => {
  return schools.map(x => keyMergeSchool(x));
}
const keyMergeProjects = (projects) => {
  return projects.map(x => keyMergeProject(x));
}
export { keyMergeProjects, keyMergeSchools}